/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
.menu-sidebar {
  width: 280px;
  background: #222626; }
  .menu-sidebar .new-video {
    background-color: #c1526f; }
  .menu-sidebar hr {
    margin-top: 0;
    margin-bottom: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.5); }
  .menu-sidebar .menu-links {
    font-family: 'Noto Sans', sans-serif; }
    .menu-sidebar .menu-links li {
      display: grid; }
      .menu-sidebar .menu-links li i {
        position: relative; }
        .menu-sidebar .menu-links li i .badge {
          background-color: #e03737;
          border-radius: 20px;
          color: white;
          padding: 1px 4px;
          font-size: 12px;
          position: absolute;
          /* Position the badge within the relatively positioned button */
          top: -8px;
          right: -5px; }
    .menu-sidebar .menu-links a:focus {
      outline: none !important;
      box-shadow: none !important; }
    .menu-sidebar .menu-links .btn {
      color: #ffffff;
      padding: 5px 25px;
      margin-left: 0;
      margin-right: 0;
      border-radius: 0;
      text-align: left;
      cursor: pointer; }
      .menu-sidebar .menu-links .btn:hover {
        text-decoration: none;
        background: #0a0b0b; }
    .menu-sidebar .menu-links .channels {
      padding: 5px 40px;
      text-decoration: none;
      display: block; }
    .menu-sidebar .menu-links .user {
      color: #ffffff;
      padding: 5px 25px;
      margin-left: 0;
      margin-right: 0;
      border-radius: 0; }
      .menu-sidebar .menu-links .user.btn:hover {
        background: #0a0b0b; }
  .menu-sidebar .menu-header {
    background: #222626;
    text-align: center;
    height: 96px; }
    .menu-sidebar .menu-header img {
      padding: 5px 0 0;
      height: 90px; }

.dropdown {
  display: inline-block;
  width: 100%;
  user-select: none; }
  .dropdown .dropdown__trigger {
    font-size: 15px;
    color: #ffffff;
    width: 100px;
    cursor: pointer;
    width: auto; }
    .dropdown .dropdown__trigger .selected-operator {
      font-weight: bold;
      color: white;
      margin: auto; }
      .dropdown .dropdown__trigger .selected-operator i.pull-right {
        float: right;
        padding-top: 4px; }
  .dropdown .dropdown__content {
    z-index: 9999;
    display: none;
    position: absolute;
    background-color: #5A937D;
    margin-top: 10px;
    width: 280px;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 4px;
    max-height: 260px;
    overflow: auto; }
    .dropdown .dropdown__content a {
      color: white;
      padding: 10px 40px;
      text-decoration: none;
      display: block; }
      .dropdown .dropdown__content a:hover {
        background-color: #464e4e; }
  .dropdown.dropdown--active .dropdown__content {
    display: block; }
