/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
.progress {
  width: 100% !important;
  background-color: #474747;
  height: 25px;
  width: 350px;
  margin: 0;
  border-radius: 5px;
  border-color: transparent; }
  .progress .progress-bar {
    color: white;
    display: inline-block;
    height: 100%;
    border-radius: 5px;
    transition: width .4s ease-in-out;
    background-color: #222626; }
    .progress .progress-bar .progress-value {
      padding-left: 5px; }
