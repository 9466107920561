/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
.denied {
  position: absolute;
  top: 96px;
  bottom: 0;
  border-radius: 5px;
  border-color: #469696; }
  .denied .denied-icon {
    position: relative;
    top: calc(40% - 10px);
    /* 50% - 3/4 of icon height */ }
    .denied .denied-icon p {
      font-size: 20px;
      margin: 10px; }
    .denied .denied-icon i {
      color: #c1526f; }
