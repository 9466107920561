/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
.image-dropzone {
  align-items: center; }
  .image-dropzone .image-dropzone-input {
    overflow: hidden;
    width: 300px; }
  .image-dropzone .div-delete-thumb {
    width: 100%;
    padding-left: 5px;
    text-align: center; }
    .image-dropzone .div-delete-thumb #thumbnail-preview {
      height: 200px; }
    .image-dropzone .div-delete-thumb .btn-delete-thumb span {
      color: #474747;
      margin-left: 5px; }
    .image-dropzone .div-delete-thumb .btn-delete-thumb:hover {
      color: #e03737; }

.thumb-dropzone {
  position: relative;
  padding-top: 20px;
  bottom: 0;
  border-radius: 5px;
  border-color: #474747;
  border-style: dashed;
  border-width: 1px; }
  .thumb-dropzone:hover {
    background-color: rgba(218, 219, 219, 0.6); }
  .thumb-dropzone .dropzone-icon {
    position: relative; }
    .thumb-dropzone .dropzone-icon img, .thumb-dropzone .dropzone-icon #thumbnail-preview {
      height: 150px;
      margin-bottom: 20px; }
    .thumb-dropzone .dropzone-icon i.upload-icon {
      animation-name: cloud;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      color: #c1526f; }

@keyframes cloud {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(-15px); }
  100% {
    transform: translateY(0px); } }
  .thumb-dropzone .dropzone-input {
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0;
    opacity: 0; }
