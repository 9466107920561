/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
.li {
  display: inline-flex;
  margin-right: 10px; }

.alert-summary {
  color: red !important; }

.alert-info {
  background-color: #A3CDF0 !important; }
  .alert-info, .alert-info hr {
    border-color: #8dc1ec !important; }

.text-sm .dashboard-value {
  font-size: 33px !important; }
