.filterButtons, .filterTags {
  margin-top: 10px;
  clear: left; }

.showFiltersButton {
  margin-top: 2px;
  margin-left: 5px; }

.rw-multiselect-tag {
  margin-top: -3px !important; }
