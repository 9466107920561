/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
#root > .flash-message-container {
  position: fixed;
  top: 0; }

.flash-message-container {
  width: stretch;
  position: absolute;
  z-index: 9999;
  height: 28px;
  font-weight: bold !important;
  user-select: none;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); }
  .flash-message-container.error {
    background-color: #e03737; }
  .flash-message-container.success {
    background-color: #5A937D; }
  .flash-message-container.warning {
    background-color: #B57628; }
  .flash-message-container span {
    margin-left: 3%;
    font-weight: bold;
    color: #ffffff; }
  .flash-message-container .button {
    background-color: inherit;
    border: none;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    margin-right: 2%;
    outline: none; }
