/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
.page {
  height: 100%; }
  .page .content {
    margin-top: 1rem; }
  .page .page-header {
    padding: 24px 0;
    color: #474747;
    background-color: #cdcdcd;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); }
    .page .page-header h1 {
      display: inline-block;
      margin: 0;
      padding: 0; }
  .page a:focus {
    outline: none !important;
    box-shadow: inset 0px 4px 5px 0px rgba(0, 0, 0, 0.07); }
  @media only screen and (max-width: 489px) {
    .page .menu-button {
      display: inline-block;
      padding: 10px 10px;
      border: 0px; }
      .page .menu-button i {
        padding-right: 0px; } }
  @media only screen and (min-width: 490px) {
    .page .menu-button {
      display: none; } }
  .page .content-sidebar {
    min-width: 150px; }
  .page .navbar-menu {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    text-align: left;
    margin: 0; }
    .page .navbar-menu ul {
      margin: 0; }
    .page .navbar-menu li {
      display: inline-block; }
      .page .navbar-menu li .btn-secondary {
        padding: 10px 25px 10px 0; }
