/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
.react-confirm-alert-overlay {
  animation: none;
  opacity: 1; }
  .react-confirm-alert-overlay .custom-ui {
    width: 500px;
    padding: 25px;
    text-align: center;
    background: #85b4a2;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); }
    .react-confirm-alert-overlay .custom-ui,
    .react-confirm-alert-overlay .custom-ui p {
      color: #fff !important; }
  .react-confirm-alert-overlay .custom-ui > h1 {
    margin-top: 0; }
  .react-confirm-alert-overlay .custom-ui > button {
    width: 160px;
    margin: 10px; }
