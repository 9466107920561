/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
.form div[class^="rw"], .form .form-control {
  background-color: #f2f2f2; }
  .form div[class^="rw"]:focus, .form .form-control:focus {
    box-shadow: none;
    border-color: #6d6d6d;
    background-color: #f2f2f2 !important; }

.form div[class^="rw"]:hover {
  background-color: #f2f2f2; }

.form .rw-state-focus > .rw-widget-container, .form .rw-list-option.rw-state-focus {
  box-shadow: none;
  border-color: #6d6d6d; }

.form .form-group {
  padding-bottom: 10px; }
  .form .form-group .input-group {
    padding: 0; }
    .form .form-group .input-group img {
      height: 200px; }
    .form .form-group .input-group input, .form .form-group .input-group select {
      margin: 0 auto;
      padding: 8px;
      box-shadow: none;
      background-color: #f3f2f2;
      border: 1px solid #6d6d6d;
      border-radius: 4px; }
    .form .form-group .input-group.input-group-error input, .form .form-group .input-group.input-group-error select {
      border: 1px solid #e03737; }
    .form .form-group .input-group .warning {
      font-size: 12px;
      color: #477362; }
    .form .form-group .input-group .react-toggle {
      margin-bottom: -5px;
      /* Optimized with a zoomed-in screen ruler! */ }
      .form .form-group .input-group .react-toggle.react-toggle--checked .react-toggle-track {
        background-color: #222626; }
      .form .form-group .input-group .react-toggle.react-toggle--focus .react-toggle-thumb {
        box-shadow: 0 0 2px 3px #5A937D; }
      .form .form-group .input-group .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
        box-shadow: 0 0 2px 3px #477362; }
  .form .form-group .rw-multiselect-tag {
    margin-top: -3px; }
  .form .form-group.form-group-inline {
    display: inline-block;
    margin-right: 10px; }
    .form .form-group.form-group-inline .error {
      margin-left: 5px;
      display: inline-block; }

.form .fieldarray .fieldarray-item {
  background-color: #85b4a2;
  padding: 5px 20px;
  margin: 5px 0;
  border-radius: 5px; }
  .form .fieldarray .fieldarray-item label, .form .fieldarray .fieldarray-item p, .form .fieldarray .fieldarray-item h1, .form .fieldarray .fieldarray-item h2, .form .fieldarray .fieldarray-item h3, .form .fieldarray .fieldarray-item button {
    color: #2e2e2e; }
  .form .fieldarray .fieldarray-item button {
    color: #e03737; }
  .form .fieldarray .fieldarray-item .input-group-widget {
    max-width: 300px; }
  .form .fieldarray .fieldarray-item .rw-list-option.rw-state-selected,
  .form .fieldarray .fieldarray-item .rw-list-option.rw-state-selected:hover {
    background-color: #85b4a2;
    border-color: #85b4a2;
    color: #2e2e2e; }

.form .error {
  padding-top: 2px;
  font-size: 13px;
  color: #ea7979; }
