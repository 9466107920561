/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
.dropzone {
  position: absolute;
  top: 96px;
  bottom: 0;
  border-radius: 5px;
  border-color: #469696; }
  .dropzone:hover {
    background-color: rgba(218, 219, 219, 0.6); }
  .dropzone .dropzone-icon {
    position: relative;
    top: calc(40% - 10px);
    /* 50% - 3/4 of icon height */ }
    .dropzone .dropzone-icon i {
      animation-name: cloud;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      color: #c1526f; }

@keyframes cloud {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(-15px); }
  100% {
    transform: translateY(0px); } }
    .dropzone .dropzone-icon p {
      font-size: 20px; }
  .dropzone .dropzone-input {
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0; }
