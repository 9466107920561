/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
body {
  color: #474747;
  background: #E6E6E6 !important;
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Noto Sans', sans-serif; }

p, a, label, strong, small, span, ul, li, input, option, select, div {
  font-family: 'Poppins', sans-serif; }

p, a, label, strong, span, ul, li, input, option, select {
  color: #474747;
  font-size: 15px; }

input, option, select {
  color: #2e2e2e; }

ul, li {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.pull-right {
  float: right; }

.summary .warning {
  color: #B57628; }

.summary .error {
  color: #e03737; }

.summary .properties li {
  padding: 5px 0; }

.summary .properties .property {
  font-weight: bold;
  padding-right: 10px; }

.summary .images .image-item {
  width: fit-content;
  margin: 0 5px; }
  .summary .images .image-item img {
    height: 200px; }

.card {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #6d6d6d;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  padding: 20px;
  margin: 5px 0; }

.btn-primary {
  background-color: #5A937D !important;
  border-color: #5A937D !important; }
  .btn-primary:hover {
    background-color: #477362 !important;
    border-color: #477362 !important; }

.btn-danger {
  background-color: #c1526f !important;
  border-color: #c1526f !important; }
  .btn-danger:hover {
    background-color: #a53b57 !important;
    border-color: #a53b57 !important; }

.btn-outline-danger {
  color: #c1526f !important;
  border-color: #c1526f !important; }
  .btn-outline-danger:hover {
    background-color: #c1526f !important;
    color: white !important; }

.btn-outline-info {
  color: #469696 !important;
  border-color: #469696 !important; }
  .btn-outline-info:hover {
    background-color: #469696 !important;
    color: white !important; }

button.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(109, 109, 109, 0.3) !important; }

input {
  background-color: #f2f2f2 !important; }
  input:focus {
    box-shadow: none !important;
    border-color: #6d6d6d !important;
    background-color: #f2f2f2 !important; }
