/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
.table .operations {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  .table .operations .filters .search {
    float: left; }
    .table .operations .filters .search input {
      padding: 8px;
      width: 300px;
      box-shadow: none;
      background-color: #f3f2f2;
      border: 1px solid #6d6d6d;
      border-radius: 4px;
      font-family: 'Font Awesome 5 Pro', 'Poppins', sans-serif; }
      @media only screen and (max-width: 768px) {
        .table .operations .filters .search input {
          width: 100%; } }
      .table .operations .filters .search input:focus {
        box-shadow: none;
        border-color: #6d6d6d; }
    .table .operations .filters .search button {
      margin-left: 10px; }
  @media only screen and (max-width: 768px) {
    .table .operations .filters {
      width: 100%; } }

.table .actions {
  float: right; }
  .table .actions div {
    display: inline-block; }
    @media only screen and (max-width: 768px) {
      .table .actions div {
        margin-top: 10px;
        width: 300px; } }
    @media only screen and (max-width: 489px), screen and (max-height: 489px) and (orientation: landscape) {
      .table .actions div {
        width: 100%; } }

.table .table-item-subcomponent {
  padding: 10px 0 30px 40px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 5px; }
  .table .table-item-subcomponent .rt-thead {
    background-color: #6d6d6d !important; }

.table .ReactTable {
  margin-top: 10px;
  border: none; }
  .table .ReactTable .rt-thead {
    background-color: #222626;
    box-shadow: none; }
  .table .ReactTable .rt-th {
    text-align: center;
    color: white;
    white-space: pre-wrap;
    align-items: stretch; }
    .table .ReactTable .rt-th:hover {
      text-decoration: underline; }
    .table .ReactTable .rt-th.-sort-asc {
      box-shadow: inset 0 4px 0 0 #5A937D; }
    .table .ReactTable .rt-th.-sort-desc {
      box-shadow: inset 0 -4px 0 0 #5A937D; }
  .table .ReactTable .rt-expander:after {
    border-top: 7px solid #222626; }
  .table .ReactTable .rt-tr {
    align-items: center; }
    .table .ReactTable .rt-tr .rt-td {
      text-align: center;
      white-space: pre-wrap;
      border-right: none; }
      .table .ReactTable .rt-tr .rt-td img {
        max-width: 100%; }
  .table .ReactTable .pagination-bottom .-pagination {
    margin-top: 30px;
    border-top: 0;
    box-shadow: none; }
