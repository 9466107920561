/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
.top-buffer {
  margin-top: 20px; }

a.dashboard-entry .dashboard-inner:hover {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15); }

.dashboard-entry {
  display: inline-block;
  text-decoration: none !important; }
  .dashboard-entry .dashboard-inner {
    display: flex;
    align-items: flex-start;
    width: 365px;
    height: 145px;
    border-radius: 25px;
    margin: 0 30px 30px 0;
    padding: 20px 20px 20px 0;
    color: #ffffff;
    background-color: #464e4e; }
    .dashboard-entry .dashboard-inner.stats {
      width: 330px; }
    .dashboard-entry .dashboard-inner.success {
      background-color: #75ab96; }
    .dashboard-entry .dashboard-inner.warning {
      background-color: #d4903c; }
    .dashboard-entry .dashboard-inner.danger {
      background-color: #ea7979; }
    .dashboard-entry .dashboard-inner.info {
      background-color: #40b3ff; }
    .dashboard-entry .dashboard-inner.secondary {
      background-color: #a7a7a7; }
    .dashboard-entry .dashboard-inner .dashboard-icon {
      padding: 20px;
      border-right: 2px solid white; }
    .dashboard-entry .dashboard-inner .dashboard-data {
      flex: 1;
      vertical-align: middle;
      margin-left: 20px; }
      .dashboard-entry .dashboard-inner .dashboard-data .dashboard-value {
        font-size: 40px;
        margin-bottom: 14px; }
      .dashboard-entry .dashboard-inner .dashboard-data .dashboard-value-sm {
        font-size: 30px;
        margin-bottom: 14px; }
      .dashboard-entry .dashboard-inner .dashboard-data .dashboard-title {
        font-size: 16px;
        line-height: 15px; }
