.markdown-editor {
  border: 1px solid #9c9b9c !important;
  padding: 2px !important;
  border-radius: 4px !important;
  height: 200px !important; }

.rdw-editor-toolbar {
  border: 1px solid #9c9b9c !important;
  border-radius: 4px !important; }

.rw-widget .rw-calendar-popup {
  width: 100% !important; }
