/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
body.annon-page {
  background-color: #222626; }

.annon-form {
  flex-basis: auto;
  color: #ffffff;
  background-color: #222626;
  border-radius: 5px;
  box-shadow: 0px 0px 8px 0px black;
  padding: 10px; }
  .annon-form label, .annon-form a {
    color: #ffffff;
    text-decoration: none; }
  .annon-form .form {
    margin: 20px; }
    .annon-form .form h2 {
      margin-top: 0; }
  .annon-form label {
    font-weight: bold; }
  .annon-form input {
    font-size: 20px;
    margin: 0 auto;
    height: 48px; }
  .annon-form .register-form .rw-dropdown-list {
    width: auto;
    margin: 0 auto; }
    .annon-form .register-form .rw-dropdown-list .rw-widget-container {
      border: 1px solid #ced4da;
      border-radius: .25rem; }
    .annon-form .register-form .rw-dropdown-list .rw-btn-select {
      padding: 10px 10px; }
  .annon-form .forgot-link {
    margin: auto;
    margin-bottom: 3%;
    text-align: right; }
  .annon-form a:hover {
    color: #85b4a2; }
  .annon-form .btn-primary {
    width: 318px; }
  .annon-form .form-error {
    max-width: 318px;
    color: #e03737;
    font-size: 15px; }

.index {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  font-size: 20px; }
  .index img {
    width: 225px;
    margin: 0 0 0 15px; }
  @media only screen and (max-width: 489px), screen and (max-height: 489px) and (orientation: landscape) {
    .index {
      max-height: 100vmax; } }
  .index .flash-message-container {
    width: inherit; }

.Aligner {
  display: flex;
  align-items: center;
  justify-content: center; }

.Aligner-item {
  max-width: 50%; }

.flex-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center; }
  @media only screen and (max-width: 991px) {
    .flex-container {
      justify-content: center;
      width: 450px; } }
