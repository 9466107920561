/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
.li {
  display: inline-flex;
  margin-right: 10px; }

@media only screen and (max-width: 991px) {
  .highlight {
    width: auto; } }

.highlight .iframe {
  text-align: center;
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; }
  .highlight .iframe iframe {
    border-style: hidden;
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.highlight .iframe-code {
  margin: auto; }
  @media only screen and (max-width: 991px) {
    .highlight .iframe-code {
      width: auto; } }
  .highlight .iframe-code h5 {
    text-align: center; }
  .highlight .iframe-code pre {
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px; }

.alert-summary {
  color: red !important; }
